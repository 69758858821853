const statuses = {
	free: {
		value: 'free',
		label: 'Свободно',
	},
	reserved: {
		value: 'reserved',
		label: 'Резерв',
	},
	temporary_approved: {
		value: 'temporary_approved',
		label: 'Временное подтверждение',
	},
	approved: {
		value: 'approved',
		label: 'Подтверждение',
	},
};


const approvalTypes = [
	{
		value: 'all',
		label: 'Все',
		path: 'all'
	},
	{
		value: 'discount',
		label: 'Скидка',
		path: 'discount'
	},
	{
		value: 'changeStatus',
		label: 'Статус',
		path: 'status'
	},
	{
		value: 'placementType',
		label: 'Тип размещения',
		path: 'placement'
	},
	{
		value: 'product',
		label: 'Продукт',
		path: 'product'
	},
];

const approvalOptions = {};
for( const item of approvalTypes ){
	const { value } = item;
	approvalOptions[value] = item;
}

export { statuses, approvalTypes, approvalOptions };